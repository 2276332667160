import { fetchShipmentDetails, deleteShipment } from '../services/shipmentService'

// Function to process shipment data
export const processShipmentsData = (data) => {
  const newLogisticsCount = {};
  const newLogisticsValue = {};

  let totalShipments = 0;
  let totalValue = 0;

  const updatedShipments = data.filter(shipment => {
    const statusCode = shipment.shipment.current_status_code;
    const status = shipment.shipment.current_status;

    if (statusCode === "RT" || statusCode === "DL" || statusCode === "CN" ||
      (statusCode === "UD" && status === "In Transit")) {
      console.log("processShipmentsData: deleting shipment: ", shipment.shipment.awb_no);
      deleteShipment(shipment.shipment.awb_no);
      return false;
    }

    totalShipments++;
    const logistic = shipment.shipment.logistic || 'Unknown';
    const value = Number(shipment.shipment.order_details.net_payment) || 0;
    totalValue += value; // Update total value
    newLogisticsCount[logistic] = (newLogisticsCount[logistic] || 0) + 1; // Update logistics count
    newLogisticsValue[logistic] = (newLogisticsValue[logistic] || 0) + value; // Update logistics value

    return true;
  });

  return {
    updatedShipments,
    newLogisticsCount,
    newLogisticsValue,
    totalValue
  };
};

export const fetchAllShipmentDetails = async (trackingIds) => {
  const detailsPromises = trackingIds.map(trackingId => fetchShipmentDetails(trackingId));
  return Promise.all(detailsPromises);
};

// Add other utility functions if needed
