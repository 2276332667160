export const getToken = () => {
    return localStorage.getItem('authToken');
};

export const handleApiResponse = (response) => {
    if (response.ok) {
        return response.json();
    } else if (response.status === 401) {
        throw new Error('Unauthorized. Please log in.');
    } else if (response.status === 403) {
        localStorage.removeItem('authToken');
        localStorage.removeItem('tokenExpiration');
        localStorage.removeItem('userName');
        //setIsAuthenticated(false);
        //setUserName(''); // Clear user name
        throw new Error('Forbidden. Token may be invalid or expired.');
    } else {
        throw new Error(response);
    }
};