import { getToken, handleApiResponse } from '../utils/commonUtils'

export const fetchShipments = async () => {
  const response = await fetch('/api/shipments', {
    headers: {
      'Authorization': `Bearer ${getToken()}`
    }
  });
  return handleApiResponse(response);
};

export const deleteShipment = async (trackingId) => {
  const response = await fetch(`/api/shipments/${trackingId}`, {
    headers: {
      'Authorization': `Bearer ${getToken()}`
    },
    method: 'DELETE'
  });
  return handleApiResponse(response);
};

export const fetchShipmentDetails = async (trackingId) => {
  const response = await fetch(`/api/shipments/${trackingId}`, {
    headers: {
      'Authorization': `Bearer ${getToken()}`
    }
  });
  return handleApiResponse(response);
};

export const addComment = async (trackingId, comment) => {
  const response = await fetch(`/api/shipments/${trackingId}/comments`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${getToken()}`
    },
    body: JSON.stringify(comment),
  });
  return handleApiResponse(response);
};
