// src/utils/csvUtils.js

export const jsonToCsv = (jsonData) => {
  const csvRows = [];

  // Function to recursively flatten objects
  const flattenObject = (obj, prefix = '') => {
    if (Array.isArray(obj)) {
      // Concatenate array items into a string
      return {
        [prefix]: obj.map(item => {
          if (typeof item === 'object') {
            return JSON.stringify(item); // Convert objects to string
          }
          return item;
        }).join('; ')
      }; // Use a separator to join array items
    }

    if (typeof obj === 'object' && obj !== null) {
      return Object.keys(obj).reduce((acc, key) => {
        const value = obj[key];
        const newKey = prefix ? `${prefix}.${key}` : key;
        Object.assign(acc, flattenObject(value, newKey)); // Recursion for nested objects
        return acc;
      }, {});
    }

    return { [prefix]: obj }; // Return the value for primitive types
  };

  // Flatten each object in the data
  const flattenedData = jsonData.flatMap(item => flattenObject(item));

  // Get headers from the first flattened object
  const headers = Array.from(new Set(flattenedData.flatMap(Object.keys)));
  csvRows.push(headers.join(','));

  // Format each row
  for (const row of flattenedData) {
    const values = headers.map(header => {
      const escaped = ('' + (row[header] || '')).replace(/"/g, '\\"'); // Escape double quotes
      return `"${escaped}"`; // Wrap in quotes
    });
    csvRows.push(values.join(','));
  }

  return csvRows.join('\n');
};


export const downloadCsv = (filename, csv) => {
  const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
  const url = URL.createObjectURL(blob);

  const link = document.createElement('a');
  link.setAttribute('href', url);
  link.setAttribute('download', filename);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

/*

export const jsonToCsv = (jsonData) => {
const csvRows = [];
 
// Function to recursively flatten objects
const flattenObject = (obj, prefix = '') => {
  if (Array.isArray(obj)) {
    return { [`${prefix}`]: obj.join('; ') }; // Join array items with a separator
  }
  
  return Object.keys(obj).reduce((acc, key) => {
    const value = obj[key];
    const newKey = prefix ? `${prefix}.${key}` : key;
    if (typeof value === 'object' && value !== null) {
      Object.assign(acc, flattenObject(value, newKey)); // Recursion for nested objects
    } else {
      acc[newKey] = value;
    }
    return acc;
  }, {});
};

// Flatten each object in the data
const flattenedData = jsonData.map(item => flattenObject(item));
 
// Get headers from the first flattened object
const headers = Array.from(new Set(flattenedData.flatMap(Object.keys)));
csvRows.push(headers.join(','));
 
// Format each row
for (const row of flattenedData) {
  const values = headers.map(header => {
    const escaped = ('' + (row[header] || '')).replace(/"/g, '\\"'); // Escape double quotes
    return `"${escaped}"`; // Wrap in quotes
  });
  csvRows.push(values.join(','));
}
 
return csvRows.join('\n');
};


*/
