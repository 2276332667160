import React, { useState } from 'react';
import axios from 'axios';
import { Form, Button } from 'react-bootstrap';

const OTPLogin = ({ onSuccess }) => {
  const [email, setEmail] = useState('');
  const [otp, setOtp] = useState('');
  const [step, setStep] = useState('request');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  const handleRequestOtp = async () => {
    try {
      await axios.post('/request-otp', { email });
      setStep('verify');
      setSuccess('OTP sent successfully to your registered Email.');
      setError('');
    } catch (error) {
      setError('Failed to send OTP. Please try again.');
      setSuccess('');
    }
  };

  const handleVerifyOtp = async () => {
    try {
      const response = await axios.post('/verify-otp', { email, otp });
      const { token, expiresIn, user } = response.data; // Include user details in response

      localStorage.setItem('authToken', token);
      localStorage.setItem('tokenExpiration', (Date.now() + expiresIn * 1000).toString()); // Store expiration time
      localStorage.setItem('userName', user.name); // Store user name

      setSuccess('OTP verified successfully. You are now logged in.');
      setError('');
      onSuccess();
    } catch (error) {
      setError('Invalid or expired OTP. Please try again.');
      setSuccess('');
    }
  };

  return (
    <div>
      {step === 'request' ? (
        <div className='login-section'>
          <div className="input-section">
            <Form.Control
              type="email"
              placeholder="Enter your email"
              id='emailEntry'
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <Button onClick={handleRequestOtp}>Login</Button>
          </div>
        </div>
      ) : (
        <div className='login-section'>
          <div className="input-section">
            <Form.Control
              type="text"
              placeholder="Enter OTP"
              id='otpEntry'
              value={otp}
              onChange={(e) => setOtp(e.target.value)}
            />
            <Button onClick={handleVerifyOtp}>Verify OTP</Button>
          </div>
        </div>
      )}
      {error && <p style={{ color: 'red', textAlign: 'center' }}>{error}</p>}
      {success && <p style={{ color: 'green', textAlign: 'center' }}>{success}</p>}
    </div>
  );
};

export default OTPLogin;
