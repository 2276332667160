export const formatDate = (dateString) => {
  const date = new Date(dateString);
  return date.toLocaleString();
};

export const daysDifference = (shipment) => {
  const pickupDateString = shipment.order_date_time.pickup_date;
  const pickupDate = new Date(pickupDateString);
  const today = new Date();
  today.setHours(0, 0, 0, 0);
  const timeDifference = today - pickupDate;
  const millisecondsPerDay = 1000 * 60 * 60 * 24;
  return Math.floor(timeDifference / millisecondsPerDay);
};
