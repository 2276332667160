import React from 'react';
import { Table } from 'react-bootstrap';

const ShipmentSummary = ({ shipments, logisticsCount, logisticsValue, totalValue }) => (
  <Table id="summary-table" striped bordered hover className='summary-table'>
    <thead>
      <tr>
        <th>Total <div className='money'>₹{totalValue.toFixed(0)}</div></th>
        {Object.keys(logisticsCount).map(logistic => (
          <th key={logistic}>{logistic} <div className='money'>₹{logisticsValue[logistic].toFixed(0)}</div></th>
        ))}
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>{shipments.length}</td>
        {Object.values(logisticsCount).map(count => (
          <td key={count}>{count}</td>
        ))}
      </tr>
    </tbody>
  </Table>
);

export default ShipmentSummary;
